import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`V1.1`}</p>
    </blockquote>
    <h2>{`Introduction`}</h2>
    <h3>{`Purpose`}</h3>
    <p>{`The purpose of CoreMedia Security Policies and Regulations is to protect the company's assets, data, and employees, as well as to ensure compliance with relevant laws and regulations. These policies and regulations apply to all employees, contractors, and third parties who have access to CoreMedia's systems, networks, and data.`}</p>
    <h3>{`Accountabilities`}</h3>
    <p>{`All employees are responsible for following these policies and regulations, as well as for reporting any potential security incidents or breaches to the appropriate authorities. Management is responsible for enforcing these policies and for providing the necessary resources and training to ensure compliance.`}</p>
    <h3>{`Extent`}</h3>
    <p>{`These policies and regulations apply to all CoreMedia employees, contractors, and third parties who have access to the company's systems, networks, and data. This includes, but is not limited to, the use of company-owned or personal devices, the use of company-owned or personal email accounts, and the use of company-owned or personal software and applications. These policies and regulations also apply to any external vendors or partners that have access to CoreMedia's systems, networks, and data.`}</p>
    <h2>{`Compliance with the Security Policies`}</h2>
    <h3>{`Responsibility of Employees on Termination`}</h3>
    <p>{`Upon termination of employment with CoreMedia, all employees are responsible for returning any company-owned equipment and property, including but not limited to laptops, cell phones, access cards, and keys. Employees are also responsible for ensuring that all company data and confidential information is removed from their personal devices and/or returned to the company. Any failure to comply with these requirements may result in legal action.`}</p>
    <h3>{`Systems Information Compliance`}</h3>
    <p>{`CoreMedia is committed to ensuring the confidentiality, integrity, and availability of our systems and data. To maintain compliance with relevant laws and regulations, employees are required to follow all security policies and procedures, including but not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Protecting login credentials and not sharing them with others`}</li>
      <li parentName="ul">{`Reporting any security incidents or potential breaches immediately`}</li>
      <li parentName="ul">{`Ensuring that all company data is handled and stored securely`}</li>
      <li parentName="ul">{`Using company-approved software and applications only`}</li>
      <li parentName="ul">{`Using company-owned or personal devices in a secure manner`}</li>
      <li parentName="ul">{`Refraining from accessing or using company systems or data for personal gain`}</li>
    </ul>
    <p>{`Any employee found to be in violation of these policies and procedures may face disciplinary action, up to and including termination of employment. CoreMedia reserves the right to monitor employee activity and access to company systems and data as necessary to ensure compliance with these policies.`}</p>
    <h3>{`Implementation of Standard Controls`}</h3>
    <p>{`To ensure the security and integrity of our systems and data, CoreMedia has implemented a set of standard controls to protect against potential threats and vulnerabilities. These controls include, but are not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Firewalls to protect against external threats`}</li>
      <li parentName="ul">{`Antivirus software to detect and prevent malware infections`}</li>
      <li parentName="ul">{`Encryption of sensitive data in storage and in transit`}</li>
      <li parentName="ul">{`Two-factor authentication for access to sensitive systems and data`}</li>
      <li parentName="ul">{`Regular updates and patches to software and systems`}</li>
      <li parentName="ul">{`Employee training and awareness programs to promote good security practices`}</li>
    </ul>
    <p>{`CoreMedia will review and update these controls on a regular basis to ensure they are effective in protecting our assets. Employees are responsible for following all standard controls and procedures, as well as reporting any potential vulnerabilities or weaknesses to the appropriate authorities.`}</p>
    <h2>{`Technical Compliance Verification`}</h2>
    <h3>{`Audits of Productive Backup Platforms`}</h3>
    <p>{`To ensure that our systems and data are secure and compliant with relevant laws and regulations, CoreMedia performs regular audits of our productive backup platforms. These audits are designed to ensure that all data is being backed up correctly and can be restored in the event of a disaster.`}</p>
    <p>{`During these audits, our IT team will review the configuration of our backup systems, the frequency of backups, and the procedures in place for testing and restoring data. They will also verify that all data is encrypted in storage and in transit to protect against unauthorized access.`}</p>
    <p>{`Any issues or non-compliances identified during these audits will be addressed immediately to ensure the security and integrity of our systems and data. Employees are responsible for cooperating with these audits and providing any necessary information or access to systems as required.`}</p>
    <h3>{`Assessment of Security Risks in Information Systems`}</h3>
    <p>{`To ensure the security of our systems and data, CoreMedia regularly assesses the security risks in our information systems. This may include conducting vulnerability assessments and penetration tests, as well as reviewing our security policies and procedures to identify any potential weaknesses or vulnerabilities.`}</p>
    <p>{`During these assessments, our IT team will identify and prioritize potential risks, and develop a plan to mitigate or eliminate them. This may include implementing additional security controls, updating software or systems, or providing additional employee training.`}</p>
    <p>{`Employees are responsible for cooperating with these assessments and providing any necessary information or access to systems as required. They are also responsible for reporting any potential security risks or vulnerabilities to the appropriate authorities. CoreMedia will take all necessary steps to address identified risks and ensure the security and integrity of our systems and data.`}</p>
    <h2>{`Compliance and Enforcement`}</h2>
    <h3>{`Consequences of Non-Compliance`}</h3>
    <p>{`CoreMedia takes non-compliance with our security policies and regulations seriously. Any employee found to be in violation of these policies and procedures may face disciplinary action, up to and including termination of employment.`}</p>
    <p>{`In addition to disciplinary action, non-compliance may also result in legal consequences for both the employee and the company. CoreMedia reserves the right to report any potential violations to the appropriate authorities, including law enforcement and regulatory agencies.`}</p>
    <p>{`To ensure compliance with these policies and regulations, CoreMedia may also implement additional security measures, such as monitoring employee activity and access to company systems and data. Employees are responsible for cooperating with any additional security measures and for following all policies and procedures to ensure the security and integrity of our systems and data.`}</p>
    <h3>{`Enforcement Procedures`}</h3>
    <p>{`CoreMedia takes any potential violations of our security policies and regulations seriously and has established procedures for enforcing these policies.`}</p>
    <p>{`If an employee is found to be in violation of any security policies or procedures, the following steps may be taken:`}</p>
    <ol>
      <li parentName="ol">{`Investigation: An investigation will be launched to determine the extent of the violation and the appropriate disciplinary action.`}</li>
      <li parentName="ol">{`Disciplinary Action: Depending on the severity of the violation, disciplinary action may be taken, up to and including termination of employment.`}</li>
      <li parentName="ol">{`Reporting: If the violation is deemed to be serious or involves a potential breach of sensitive data, it may be necessary to report the incident to the appropriate authorities, including law enforcement and regulatory agencies.`}</li>
      <li parentName="ol">{`Prevention: To prevent future violations, CoreMedia may implement additional security measures, such as monitoring employee activity and access to company systems and data. Employees are responsible for cooperating with any additional security measures and for following all policies and procedures to ensure the security and integrity of our systems and data.`}</li>
    </ol>
    <h2>{`Training `}{`&`}{` Awareness`}</h2>
    <h3>{`Training Programs`}</h3>
    <p>{`To promote good security practices and ensure compliance with our policies and regulations, CoreMedia provides ongoing training programs for all employees. These programs may cover a variety of topics, including but not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Password security: Employees will learn best practices for creating strong passwords and protecting them from unauthorized access.`}</li>
      <li parentName="ul">{`Phishing scams: Employees will be educated on how to identify and avoid phishing attempts and other types of cyber attacks.`}</li>
      <li parentName="ul">{`Data protection: Employees will learn about the importance of protecting sensitive data, as well as the proper handling and storage of data to ensure compliance with relevant laws and regulations.`}</li>
      <li parentName="ul">{`Physical security: Employees will be trained on the importance of protecting company assets and premises, including locking doors and securing equipment.`}</li>
      <li parentName="ul">{`Compliance: Employees will receive training on the relevant laws and regulations that apply to our business, as well as the consequences of non-compliance.`}</li>
    </ul>
    <p>{`CoreMedia will review and update these training programs on a regular basis to ensure they are effective in promoting good security practices and compliance with our policies and regulations.`}</p>
    <h3>{`Awareness Programs`}</h3>
    <p>{`In addition to formal training programs, CoreMedia also offers awareness programs to educate employees about security best practices and the importance of compliance with our policies and regulations. These programs may include:`}</p>
    <ul>
      <li parentName="ul">{`Security bulletins: Regularly-issued bulletins that highlight current threats and vulnerabilities, as well as steps employees can take to protect themselves and the company.`}</li>
      <li parentName="ul">{`Security reminders: Short, targeted messages that remind employees of important security practices, such as locking their computers when they are away from their desk.`}</li>
      <li parentName="ul">{`Security posters: Posters or other visual materials that promote good security practices, such as locking up sensitive documents or protecting login credentials.`}</li>
      <li parentName="ul">{`Security quizzes: Quizzes or other interactive materials that test employees' knowledge of security best practices and help them to identify and correct any areas of weakness.`}</li>
    </ul>
    <p>{`CoreMedia will review and update these awareness programs on a regular basis to ensure they are effective in promoting good security practices and compliance with our policies and regulations. `}</p>
    <h2>{`Review and Updates`}</h2>
    <h3>{`Frequency of Review`}</h3>
    <p>{`To ensure that our security policies and regulations are effective in protecting our assets and ensuring compliance with relevant laws and regulations, CoreMedia will review and update these policies on a regular basis. The specific frequency of review will depend on the nature of the policies and any changes in relevant laws or regulations.`}</p>
    <p>{`During the review process, our security team will assess the effectiveness of the current policies and procedures, as well as identify any potential weaknesses or vulnerabilities. Any necessary updates or changes will be made and communicated to all employees.`}</p>
    <p>{`It is important for all employees to be aware of and adhere to the most current version of our security policies and regulations at all times. Employees are responsible for staying informed about any updates or changes to these policies and for following all policies and procedures to ensure the security and integrity of our systems and data.`}</p>
    <h3>{`Process for Updating`}</h3>
    <p>{`To ensure that our security policies and regulations are effective in protecting our assets and ensuring compliance with relevant laws and regulations, CoreMedia has established a process for updating these policies on a regular basis.`}</p>
    <p>{`The process for updating our security policies and regulations includes the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Review: The security team will review the current policies and procedures, as well as identify any potential weaknesses or vulnerabilities.`}</li>
      <li parentName="ul">{`Consultation: The security team may consult with relevant departments, such as IT and Legal, to gather input on any necessary updates or changes.`}</li>
      <li parentName="ul">{`Drafting: The security team will draft any necessary updates or changes to the policies and regulations.`}</li>
      <li parentName="ul">{`Review and Approval: The updated policies and regulations will be reviewed and approved by management before being implemented.`}</li>
      <li parentName="ul">{`Communication: The updated policies and regulations will be communicated to all employees, as well as any relevant third parties, such as contractors or partners.`}</li>
    </ul>
    <p>{`It is important for all employees to be aware of and adhere to the most current version of our security policies and regulations at all times. Employees are responsible for staying informed about any updates or changes to these policies and for following all policies and procedures to ensure the security and integrity of our systems and data.`}</p>
    <h2>{`References`}</h2>
    <h3>{`Relevant Laws and Regulations`}</h3>
    <p>{`CoreMedia's security policies and regulations are based on relevant laws and regulations that apply to our business. These may include:`}</p>
    <ul>
      <li parentName="ul">{`General Data Protection Regulation (GDPR): The GDPR is a European Union (EU) regulation that sets out requirements for the protection of personal data. It applies to any company that processes personal data of EU citizens, regardless of the location of the company.`}</li>
      <li parentName="ul">{`Cybersecurity and Infrastructure Security Agency (CISA) guidelines: CISA is a US government agency that provides guidance on cybersecurity best practices for the public and private sectors.`}</li>
      <li parentName="ul">{`Payment Card Industry Data Security Standard (PCI DSS): PCI DSS is a set of security standards designed to ensure the secure handling of credit and debit card transactions. It applies to any company that processes, stores, or transmits cardholder data.`}</li>
      <li parentName="ul">{`European Union Data Protection Regulation (EU-DPR): The EU-DPR is a European Union (EU) regulation that sets out requirements for the protection of personal data. It applies to any company that processes personal data of EU citizens, regardless of the location of the company.`}</li>
    </ul>
    <p>{`CoreMedia will review and update our policies and procedures to ensure compliance with relevant laws and regulations on a regular basis. Employees are responsible for staying informed about any relevant laws and regulations and for following all policies and procedures to ensure the security and integrity of our systems and data.`}</p>
    <h3>{`Other References`}</h3>
    <p>{`In addition to relevant laws and regulations, CoreMedia's security policies and regulations may also include references to other sources of information or guidelines. These may include:`}</p>
    <ul>
      <li parentName="ul">{`Industry best practices: CoreMedia may reference best practices and guidelines from relevant industry groups or organizations, such as the European Union Agency for Cybersecurity (ENISA) or the European Network and Information Security Agency (ENISA).`}</li>
      <li parentName="ul">{`Internal documents: CoreMedia may reference internal documents, such as company policies or procedures, that are relevant to security.`}</li>
      <li parentName="ul">{`External resources: CoreMedia may reference external resources, such as security blogs or websites, that provide valuable information or guidance on security best practices.`}</li>
    </ul>
    <p>{`CoreMedia will review and update these references on a regular basis to ensure that our policies and procedures are based on the most current and relevant information. Employees are responsible for staying informed about any relevant references and for following all policies and procedures to ensure the security and integrity of our systems and data. `}</p>
    <h2>{`Attachments`}</h2>
    <p>{`This document does not contain any attachments. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      